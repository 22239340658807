<template>
  <div class="planlist-container">
    <div class="planlist-container-select" v-if="!projectName === 'Noor Play'">
      <select class="planlist-container-subscription select" v-model="planCategory" @change="planSelect(planCategory)">
        <option v-for="(item, index) in subscriptionsStatus" :value="item.value" :key="index">{{
            item.subscription
        }}</option>
      </select>
    </div>
    <div class="planlist-container-planslist">
      <div class="planlist-container-planslist-responsetext" v-if="responseError && !isPlans">
        <p>{{ responseError }}</p>
      </div>
      <div class="planlist-container-planslist-placeholder" v-else-if="!responseError && !isPlans">
        <div class="planlist-container-planslist-placeholder-card loading" v-for="(item, index) in 3" :key="index">
        </div>
      </div>
      <div class="planlist-container-planslist-plans" v-else-if="!responseError && isPlans">
        <div class="planObj" v-for="(item, index) in filteredPlanList" :key="index">
          <planCard :plan="item" :gatewayList="gatewayResponse" @subscribe="subscribe" @cancel="planCancel"
            @close="(response) => openTelcoOtpPopup(response)"></planCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { _projectName } from "@/provider-config.js";
import snackbar from "node-snackbar";
import { showSnackBar } from '../../../../utilities';

export default {
  props: {
    locale: {
      type: String,
    },
  },
  data() {
    return {
      subscriptionsStatus: [
        { subscription: this.$t("all plans"), value: "ALL" },
        { subscription: this.$t("active"), value: "ACTIVE" },
        { subscription: this.$t("on trial"), value: "ONTRIAL" },
        { subscription: this.$t("cancelled"), value: "CANCELLED" },
        { subscription: this.$t("pending"), value: "PENDING" },
      ],
      planCategory: "ALL",
      tick: require("@/assets/icons/Tick.svg"),
      responseError: "",
      isPlans: false,
      planStatus: null,
      subscribedPlans: [],
      planList: [],
      filteredPlanList: [],
      subscriptions: [],
      planSelectedForCancellation: null,
      planSelectedForReactivation: null,
      projectName: _projectName,
      gatewayResponse: [],
      isClientcancancel: "",
      subscribedPlanObject: null,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["subscriptionList", "appConfig"]),
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        // this.subscriptions = val;
        this.planSelect(this.planCategory);
      }
    },
    locale(val) {
      if (val) {
        this.planSelect("ALL");
      }
    },
    planList(val) {
      if (val.length > 0) {
        val.forEach((element) => {
          if (element.paymentoptions.includes("OPERATOR")) {
            this.checkGatewayForOperator(this.gatewayResponse, element);
          }
        });
      }
    },
  },
  created() {
    //list gateways.
    eventBus.$emit("listGateway");

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    eventBus.$on("listgateway-response", (response) => {
      this.$emit("gatewayList", response.data);
      if (!response.reason) {
        this.gatewayResponse = response.data;

        this.setGatewayList(this.gatewayResponse);
        this.planSelect("ALL");
      } else {
        this.responseError = response.reason;
        this.planSelect("ALL");


      }
    });
  },
  mounted() {
    //event handler for planlist response.
    eventBus.$on("profile-planlist-response", (response) => {
      if (response.reason) {
        this.responseError = response.reason;
      } else {
        this.planList = response.data;
        this.subscriptionListSelect();
      }
    });

    //event handler for subscription response.
    eventBus.$on("subscription-response", (response) => {
      
      this.subscribedPlans = [];
      if (!response.reason) {
        this.subscribedPlans = response.data;
        this.filterPlans();
      } else if (response.errorcode === 2001 || response.errorcode === 7109) {
        // this.responseError = `No ${this.planStatus} plans found`;
        this.listAllPlans();
        if (this.planStatus === "ALL") {
          // this.checkAllPlanStatus();
        }
      }
    });

    //event handler for subscription update response.
    eventBus.$on("subscriptionUpdate-response", (response) => {
      eventBus.$emit("enableOverlayLoader", false);

      

      eventBus.$emit("toggle-cancel-reason-popup", false);

      if (response.reason) {
        // this.responseError = response.reason;

        if (response.statusCode === 401) {
          let payloadData = localStorage.getItem("cancelPopupReasonPayload");

          if (payloadData) {
            payloadData = JSON.parse(payloadData);

            
            eventBus.$on("re-login-success", () => {
              this.cancelWithReasonFunc(payloadData.data, payloadData.plan);
              eventBus.$off("re-login-success");
            });
            return;
          } else {
            this.snackbarMessage(this.$t("Session Expired. Please try again"));
          }
          localStorage.removeItem("cancelPopupReasonPayload");
        } else {
          this.snackbarMessage(response.reason);
          localStorage.removeItem("cancelPopupReasonPayload");
        }
      } else {
        // this.setNewSubscription(false);
        this.planSelect(this.planCategory);

        //Subscription Updated event for cancel.
        let data;

        
        if (this.planSelectedForCancellation) {
          data = {
            Status: "cancelled",
            Subscription_Start_Date: this.planSelectedForCancellation.planstartdate,
            Subscription_End_Date: this.planSelectedForCancellation.nextbilling,
            Subscription_Type: this.planSelectedForCancellation.renewtype,
            Plan_Name: this.planSelectedForCancellation.planname,
            // Object_ID: ""
          };
        }

        const messageObj = {
          title: this.$t("Notice!"),
        };

    
        // if (this.planSelectedForCancellation && this.planSelectedForCancellation.subscriptionstatus !== "ACTIVE") {
        if (
          this.checkIfDirectCancel() &&
          (this.subscribedPlanObject.gwprovider === "INAPP" || this.subscribedPlanObject.gwprovider === "FIRETVINAPP")
        ) {
          const currentMessageObj = this.appConfig.indirectCancellationGateways.find(
            (ele) => ele.gatewayid === this.subscribedPlanObject.gwprovider
          );

          messageObj.message =
            this.localDisplayLang === "eng" ? currentMessageObj.message.en : currentMessageObj.message.ar;

          eventBus.$emit("show-message-popup", messageObj);
        }

        // }

        //clevertap event
        this.subscriptionUpdatedCleverEvent(data);

        //google analytics event
        this.subscriptionCancelled(data);

        localStorage.removeItem("cancelPopupReasonPayload");

        // eventBus.$off("subscriptionUpdate-response");
      }
    });
  },
  methods: {
    ...mapMutations(["setSubscriptionList", "setNewSubscription", "setGatewayList"]),
    ...mapActions(["getPlan"]),

    snackbarMessage(message) {
      // snackbar.show({
      //   text: this.$t(message),
      //   textColor: "#ffffff",
      //   pos: "bottom-center",
      //   actionTextColor: "#de2600",
      // });

      showSnackBar(this.$t(message));
    },

    checkGatewayForOperator(operators, plan) {
      if (operators.length > 0) {
        let gateways = operators.filter((el) => {
          return el.gwmode == "OPERATOR";
        });

        let index = gateways.findIndex((element) => {
          return element.ApplicaplePlanList.length > 0 && element.ApplicaplePlanList.includes(plan.planid);
        });

        if (index > -1) {
          switch (gateways[index].gatewayid) {
            case "TELCOSTCBH":
              plan.isSTC = true;
              break;
            case "TELOOREDOOQT":
              plan.isOreedo = true;
              break;
            case "TELOOREDOOOM":
              plan.isOreedo = true;
              break;
            case "TIMWEDUUAE":
              plan.isDUUAE = true;
              break;
            case "TELCOZAINKWT":
              plan.isZainKwt = true;
              break;
            case "TELCOZAINJOR":
              plan.isZainJod = true;
              break;
            case "ETISALETUAE":
              plan.isEtisalatUAE = true;
              break;
            case "TELCOZAINKSA":
              plan.isZainKsa = true;
          }
        }
      }
    },
    //function called when user changes the plan status from drop down.
    planSelect(planstatus) {
      this.isPlans = false;
      this.responseError = "";
      this.planStatus = "";
      this.filteredPlanList = [];
      this.subscribedPlans = [];
      this.planList = [];
      this.planStatus = planstatus;

      let payload = {
        planvisibility: "YES",
        displaylanguage: this.locale,
      };

      eventBus.$emit("planList_profile", payload);
    },

    //emits subscription event based on the plan status.
    subscriptionListSelect() {
      let payload = {
        subscriptionstatus: this.planStatus,
      };
      eventBus.$emit("subscriptionList", payload);
    },

    //filter plans based on plan subscription list.
    filterPlans() {
      this.responseError = "";
      // this.filteredPlanList = [];
      this.filteredPlanList = this.planList.map((plan, index)=>{
          plan['buttonStatus'] = "Subscribe Now";
          return plan;
      });
      this.subscribedPlans.forEach((subscribed) => {
        let index = this.planList.findIndex((element) => {
          return element.planid === subscribed.planid;
        });
        if (index > -1) {
          this.subscribedPlanObject = subscribed;
          this.isClientcancancel = subscribed.clientcancancel;

          if (subscribed.subscriptionstatus === "ACTIVE" || subscribed.subscriptionstatus === "ONTRIAL") {
            this.filteredPlanList[index].buttonStatus = null;
            this.filteredPlanList[index].subscriptionStatus = subscribed.subscriptionstatus;
            this.filteredPlanList[index].expiry = subscribed.nextbilling;
            this.filteredPlanList[index].gwprovider = subscribed.gwprovider;
            this.filteredPlanList[index].clientcancancel = subscribed.clientcancancel;
          } else if (subscribed.subscriptionstatus === "CANCELLED") {
            if (!this.filteredPlanList[index].paymentoptions.includes("OPERATOR")) {
              this.filteredPlanList[index].clientcanreactivate = subscribed.clientcanreactivate;
              this.filteredPlanList[index].buttonStatus = "REACTIVATE";
            }
            this.filteredPlanList[index].subscriptionStatus = subscribed.subscriptionstatus;
            this.filteredPlanList[index].expiry = subscribed.nextbilling;
          }
          // this.filteredPlanList.push(this.planList[index]);

          this.isPlans = true;
        } else {
          //call plan with planid.
          let payload = {
            planid: subscribed.planid,
          };
          this.getPlan(payload)
            .then((response) => {
              if (!response.reason) {
                let plan = response.data;
                
                if (subscribed.subscriptionstatus === "ACTIVE" || subscribed.subscriptionstatus === "ONTRIAL") {
                  plan.buttonStatus = null;
                  plan.subscriptionStatus = subscribed.subscriptionstatus;
                  plan.expiry = subscribed.nextbilling;
                } else if (subscribed.subscriptionstatus === "CANCELLED") {
                  if (!plan.paymentoptions.includes("OPERATOR")) {
                    plan.buttonStatus = "REACTIVATE";
                  }
                  plan.subscriptionStatus = subscribed.subscriptionstatus;
                  plan.expiry = subscribed.nextbilling;
                }
                this.filteredPlanList.unshift(plan);

                this.isPlans = true;
              }
            })
            .catch((error) => console.log("error", error));
        }
      });
    },

    //function called when user selects ALL plan status.
    checkAllPlanStatus() {
      this.responseError = "";

      //by default adding subscribe button to all plans.
      this.planList.forEach((element) => {
        element.buttonStatus = "Subscribe Now";
      });

      if (this.subscribedPlans.length > 0) {
        this.subscribedPlans.forEach((subscribed) => {
          //finding the matched planindex.
          let index = this.planList.findIndex((element) => {
            return element.planid === subscribed.planid;
          });

          if (index > -1) {
            if (subscribed.subscriptionstatus === "ACTIVE") {
              this.planList[index].buttonStatus = null;
              this.planList[index].subscriptionStatus = subscribed.subscriptionstatus;
              this.planList[index].expiry = subscribed.nextbilling;
            } else if (subscribed.subscriptionstatus === "CANCELLED") {
              this.planList[index].buttonStatus = "REACTIVATE";
              this.planList[index].subscriptionStatus = subscribed.subscriptionstatus;
              this.planList[index].expiry = subscribed.nextbilling;

              if (this.subscriptionList.length > 0) {
                let removeIndex = this.subscriptionList.findIndex((element) => {
                  return element.planid === this.planList[index].planid;
                });

                // if (removeIndex > -1) {
                //   this.subscriptionList.splice(index, 1);
                //   this.setSubscriptionList(this.subscriptionList);
                // }
              }
            }
          }
        });
      }
      this.filteredPlanList = this.planList;
      this.isPlans = true;

      //check for active plan
    },

    //list all plans if no subscription present(only for noor play)
    listAllPlans() {
      this.filteredPlanList = [];
      this.planList.forEach((plan) => {
        plan.buttonStatus = "Subscribe Now";
      });

      let planPrice = localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")).planPrice : [];
      let planInfoDetails = this.planList;

      this.planList.forEach((item, index) => {
        if (planPrice && planPrice.length > 0) {
          planPrice.forEach((discountData) => {
            if (discountData.planid == item.planid) {
              this.planList[index].discountData =
                discountData.originalprice && discountData.originalprice[0] ? discountData.originalprice[0] : {};
            }
          });
        }
      });

      this.filteredPlanList = this.planList;
      this.isPlans = true;
    },
    //list all subscribed plans if subscription present(only for noor play)

    //to subscribe for any plan.
    subscribe(plan) {
      if (plan.buttonStatus === "Subscribe Now") {
        let payload = {
          state: true,
          detail: plan,
          gateways: this.gatewayResponse,
          transactionPurpose: "SUBSCRIPTION",
          coupon: plan.coupon ? plan.coupon : null
        };
        // eventBus.$emit("noorPayments", payload);
        eventBus.$emit("paymentPopup", payload);
      } else if (plan.buttonStatus === "REACTIVATE") {
        eventBus.$emit("enableOverlayLoader", true);
        let payload = {
          planid: plan.planid,
          params: {
            planstatus: "ACTIVE",
          },
        };
        eventBus.$emit("subscription-update", payload);
      }
    },
    //to perform plan cancel.
    planCancel(plan) {
      eventBus.$emit("toggle-cancel-reason-popup", true);

      // const isDirectCancel = this.checkIfDirectCancel();

      eventBus.$on("submit-cancel-reponse-event", (data) => {
        const payloadData = { data, plan };
        localStorage.setItem("cancelPopupReasonPayload", JSON.stringify(payloadData));
        this.cancelWithReasonFunc(data, plan);
      });
    },

    checkIfDirectCancel() {
      let status = false;

      

      if (this.isClientcancancel == "YES") {
        status = false;
      } else {
        status = true;
      }
      return status;
    },

    cancelWithReasonFunc(data, plan) {
      const isDirectCancel = this.checkIfDirectCancel();

      let currentPlanStatus = "";

      if (isDirectCancel) {
        currentPlanStatus = "ACTIVE";
      } else {
        currentPlanStatus = "CANCEL";
      }

      this.planSelectedForCancellation = null;
      let index = this.subscriptionList.findIndex((el) => {
        return el.planid === plan.planid;
      });
      if (index > -1) {
        this.planSelectedForCancellation = this.subscriptionList[index];
      }
      eventBus.$emit("enableOverlayLoader", true);

      let payload = {
        planid: plan.planid,
        params: {
          planstatus: currentPlanStatus,
          comment: data.reason ? data.reason : "",
        },
      };
      eventBus.$emit("subscription-update", payload);
    },

    openTelcoOtpPopup(response) {
      eventBus.$emit("telcoOtpPopup", response);
    },
  },
  components: {
    planCard: () => import(/* webpackChunkName: "plancard" */ "@/components/Templates/planCard.vue"),
  },
  beforeDestroy() {
    eventBus.$off("listgateway-response");
    eventBus.$off("profile-planlist-response");
    eventBus.$off("subscription-response");
    eventBus.$off("subscriptionUpdate-response");
    eventBus.$off("submit-cancel-reponse-event");
  },
  mixins: [Utility, googleAnalytics, cleverTap],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.mt-10{
  margin-top: 20px;
}
.planlist-container {
  padding: 0 20px;
  width: 75%;

  &-select {
    margin-bottom: 20px;
  }

  &-subscription {
    width: 280px;
    padding: 10px 0px;
    border: none;
    border-bottom: 1px solid $clr-light-gd3;
    color: #ffffff;
    font-weight: 500;
    appearance: none;
    -webkit-appearance: none;
    background: url("../../../../assets/icons/down_arrow.svg") no-repeat right;
  }

  &-planslist {
    &-responsetext {
      text-align: center;

      p {
        color: $clr-saina-light-dark-txt;
        font-weight: bold;
      }
    }

    &-placeholder {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      // padding: 0 50px !important;
      gap: 0.5rem;
      &-card {
        background-color: #0d0d0d;
        border-radius: 10px;
        width: 100%;
        aspect-ratio: 9/16;
        // padding: 55px;
        overflow: hidden;
      }
    }

    &-plans {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      flex-wrap: nowrap; 
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      justify-content: flex-start;
      margin: auto;
      gap: 0.7rem;
      width: 100%;
      padding: 1rem;
      .planObj {
        height: fit-content;
        scroll-snap-align: start;
      }
    &::-webkit-scrollbar {
      height: 4px; /* Set scrollbar height */
      position: absolute;
      bottom: 0; /* Position scrollbar at the bottom */
      width: 50%; /* Full width for the horizontal scrollbar */
      transform: translateY(-50%); /* Vertically center the scrollbar */
    }

    &::-webkit-scrollbar-track {
      width: 50%;
      border-radius: .1rem
    }

    &::-webkit-scrollbar-thumb {
        width: 50%;
        background: #d31313;
        border-radius: .6rem
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #d31313
    }
    }
  }
}

.py-l {
  padding-left: 35% !important;
}

.py-r {
  padding-right: 35% !important;
}

.select {
  line-height: 16px;
  padding: 10px 5px;

  option {
    color: #000000;
  }

  &:focus {
    background: #1d1d1d;
    border: none;
    border-bottom: 1px solid $clr-light-gd3;
    background: url("../../../../assets/icons/down_arrow.svg") no-repeat right;
  }
}

.bold-text {
  color: $clr-light-gd2;
  font-weight: 600;
}

.light-text {
  color: $clr-popup-para;
  font-size: 0.8em;
}

.plan-cost {
  margin: 0.2em 0em;
  font-size: 1.5em;
}

.plan-name {
  font-size: 0.8em;
}

.expiry-date {
  margin-top: 1.2em;
}

@media only screen and (max-width: 360px) {
  .planlist-container {
    padding: 10px 20px !important;

    &-planslist {
      text-align: center;

      &-placeholder {
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;
        padding: 0;

        &-card {
          height: 150px;
          width: 100%;
        }
      }

      &-plans {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;

        display: flex !important;
        justify-content: center !important;
        width: 100%;
        flex-flow: row wrap;
        gap:50px;
        // align-items: center;
      }
    }
  }

  .plan {
    margin: 5px 0px;
  }
}

@media only screen and (min-width: 360px) {
  .planlist-container {
    width: 100%;
    margin: 0px auto;
    // align-items: center;
    padding: 10px 4px;

    &-planslist {
      text-align: center;

      &-placeholder {
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;

        padding: 0;

        &-card {
          // margin: 25px 50px;
          // height: 150px;
          // width: 100%;
        }
      }

      &-plans {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;
        // width: 100%;
        // align-items: center;
        // gap:50px;
        .planObj {
          // flex: 0 0 calc(18.66% - 20px);
          // min-height: 30.5rem;
          height: fit-content;
          overflow: hidden;
          border: 1px solid;
          min-width: 21rem;
          background: $clr-plancard-bg;
          border-radius: 12px;
          border: .3px solid $clr-plancard-border;
        }
      }
    }
  }

  .plan {
    margin: 5px 0px;
  }
}

@media only screen and (max-width: 425px) {
  .planlist-container {
    padding: 10px 20px !important;
    padding: 1rem;

    &-planslist {
      &-placeholder {
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;

        padding: 0;

        &-card {
          height: 350px;
          width: 100%;
        }
      }

      &-plans {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;
        // width: 100%;
        // flex-flow: row wrap;
        // justify-content: center;
        // align-items: center;
        // gap:50px;
      }
    }
  }

  .plan {
    margin: 5px 10px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 1199px) {
  .planlist-container {
    width: 90%;
    margin: 0px auto;
    align-items: center;
    // padding: 10px 15px;

    &-planslist {
      &-placeholder {
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;

        padding: 0;

        &-card {
          height: 450px;
          width: 100%;
        }
      }

      &-plans {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;
        // flex-flow: row wrap;
        // justify-content: center;
        // align-items: center;
        // gap:50px;
      }
    }
  }

  .plan {
    margin: 5px 10px;
  }
}
</style>
